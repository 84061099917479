import { useEffect } from "react";
import "../styles/meow.css";

const mouseController = () => {
  const parallax = document.getElementById("parallax");
  const eyeL = document.querySelector("svg#cat .eye_l");
  const eyeR = document.querySelector("svg#cat .eye_r");
  const eyeballL = document.querySelector("svg#cat .eyeball_l");
  const eyeballR = document.querySelector("svg#cat .eyeball_r");
  const fishCursor = document.getElementById("fish-cursor");

  let clientX = window.innerWidth / 2;
  let clientY = window.innerHeight * 0.8;

  window.addEventListener("scroll", (e) => {
    try {
      const top = window.scrollY;
      const ratio = 1.6;
      parallax.style.top = `-${Math.min(500, top / ratio)}px`;

      let bg = top > 500 ? "#FFFFFF80" : "#F4E5FF80";
      document.body.setAttribute("style", "--header-bg: " + bg);
      moveEyeballs();
    } catch (e) {
      console.log(`Cat not move!QAQ`, e);
    }
  });

  window.addEventListener("mousemove", (e) => {
    clientX = e.clientX;
    clientY = e.clientY;
    moveEyeballs();
  });

  const moveEyeballs = () => {
    moveEyeball(eyeL, eyeballL);
    moveEyeball(eyeR, eyeballR);
  };

  const moveEyeball = (eye, eyeball) => {
    const rect = eye.getBoundingClientRect();
    const r = rect.width / 2;
    const { x, y } = rect;
    const d = r * 0.3;
    const a = clientX;
    const b = clientY;
    const _y = b - (y + r);
    const _x = x + r - a;
    const _z = Math.sqrt(_x * _x + _y * _y);
    const ofx = parseInt((d / _z) * _x);
    const ofy = parseInt((d / _z) * _y);
    eyeball.setAttribute("style", `transform: translate(${-ofx}px, ${ofy}px)`);

    fishCursor.style.left = `${a}px`;
    fishCursor.style.top = `${b}px`;
  };

  moveEyeballs();
};

const MeowHeader = () => {
  useEffect(() => {
    try {
      mouseController();
    } catch (e) {
      console.log(`Cat not move!QAQ`, e);
    }
  }, []);
  return (
    <>
      <section id="parallax">
        <svg
          id="cat"
          width="300"
          height="200"
          viewBox="0 0 656 417"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1027_189)">
            <path
              d="M8 401.999C8.5 406.999 1.5 410.999 -0.5 416.499H649.5C655.5 415.5 655.5 422.001 655.5 403.5C655 398.499 651 399.5 649.5 394.999C648 390.498 648.5 386.501 649.5 380C650.5 373.499 644.5 374.001 641 367C637.5 359.999 625 308 604.5 289C592 279 599 256.5 599.5 244.5C599.75 238.5 603 240 604.5 231.499C606 222.998 595.5 212 595 193.499C594.5 174.998 603.5 146.001 606.5 128C609.5 109.999 608.5 55.5004 603 47C597.5 38.4996 580 -4.00087 558 0.499125C536 4.99913 499 48.4988 491 55.4988C483 62.4988 447.5 89.4987 430.5 97.4987C413.5 105.499 322.5 112.499 317 109.499C311.5 106.499 311 103.999 304 101.999C297 99.9987 284 109.499 276 109.499C268 109.499 248 101.999 239 101.999C230 101.999 220 105.499 215 105.499C210 105.499 207 101.999 201.5 101.999C196 101.999 195.5 104.499 188 103.999C180.5 103.499 72.5 17.6237 52.9999 15.9987C40.9997 14.9987 32.313 18.605 23.4999 33.9988C14.6867 49.3925 12.4999 62.4987 13 104.499C13.5001 146.499 37 190.499 35.9999 208.499C32.5 236.999 16.5 285.499 18.4999 290.499H31.4999C36 292.499 19.4999 318.999 19.4999 318.999C19.4999 318.999 13.4997 330.999 12.4999 340.499C11.5 349.999 12.4999 367.999 11 372.499C9.50013 376.999 6.50013 377.999 5 381.499C3.49987 384.999 3.49987 393.499 4.5 397.999C6.5 400.499 7 399.499 8 401.999Z"
              fill="black"
            />
            <g className="eye_l">
              <circle cx="206.5" cy="321.5" r="51.5" fill="#D9D9D9" />
              <g className="eyeball_l">
                <circle cx="206.5" cy="321.5" r="45.5" fill="black" />
                <circle cx="185" cy="314" r="3" fill="white" />
                <circle cx="202.5" cy="304.5" r="7.5" fill="white" />
              </g>
            </g>

            <g className="eye_r">
              <circle cx="446.5" cy="321.5" r="51.5" fill="#D9D9D9" />
              <g className="eyeball_r">
                <circle cx="446.5" cy="321.5" r="45.5" fill="black" />
                <circle cx="468" cy="312" r="3" fill="white" />
                <circle cx="450.5" cy="304.5" r="7.5" fill="white" />
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0_1027_189">
              <rect width="656" height="417" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </section>

      <div id="fish-cursor">
        <i className="fas fa-fish-fins"></i>
      </div>
    </>
  );
};

export default MeowHeader;
