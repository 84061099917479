import React from "react";
import { Button, Form, Input, message, Space } from "antd";
import YoutubeUrlCheck from "./Check";

const UrlQueryForm = ({ onSubmit = () => {} }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    const { url = "" } = values;
    try {
      const urlObj = new URL(url);
      const id = urlObj.search.split("?v=")[1].split("&")[0];
      onSubmit(id);
      message.success("解析视频地址成功!");
    } catch (e) {
      message.error("解析视频地址失败!");
    }
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  return (
    <Form
      form={form}
      style={{ width: 500 }}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="url"
        label="Youtube的视频地址URL"
        rules={[
          {
            type: "url",
            warningOnly: true,
          },
        ]}
      >
        <Input placeholder="https://www.youtube.com/watch?v=" />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            获取
          </Button>
          <YoutubeUrlCheck />
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UrlQueryForm;
