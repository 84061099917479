import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";

const UploadButton = () => (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      上传/更换图片
    </div>
  </div>
);

const UploadImg = ({ onUpload = () => {} }) => {
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    } else {
      onUpload(file);
    }
    return false;
  };
  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      <UploadButton />
    </Upload>
  );
};
export default UploadImg;
