import React, { useState, useRef } from "react";
import { Button, Card, Space } from "antd";
import Cropper from "react-cropper";
import UploadImg from "./UploadImg";
import "cropperjs/dist/cropper.css";
import "./Edit.module.css";

const MIME_TYPE = "image/png";

const Edit = ({ defaultSrc = "" }) => {
  const [downloadimage, setDownloadImage] = useState("#");
  const [image, setImage] = useState(defaultSrc);
  const [cropper, setCropper] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fillColor, setFillColor] = useState("#ffffff");
  const canvasRef = useRef();

  const handleOk = () => {
    const dlLink = document.createElement("a");
    dlLink.download = "bilibili_cover_1280x800";
    dlLink.href = downloadimage;
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(
      ":"
    );
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const bg = canvasRef.current;
      const bgctx = bg.getContext("2d");
      bgctx.fillStyle = fillColor;
      bgctx.fillRect(0, 0, 1280, 800);
      const cropForgroundImage = cropper.getCroppedCanvas();
      bgctx.drawImage(cropForgroundImage, 0, 0, 1280, 800);
      setDownloadImage(bg.toDataURL(MIME_TYPE));
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <UploadImg onUpload={onChange} />
      {image && (
        <>
          <Space direction="vertical" size={"middle"}>
            <Button type="primary" onClick={getCropData}>
              下载b站封面
            </Button>

            <div>
              <input
                type="color"
                id="fillColor"
                name="fillColor"
                value={fillColor}
                onChange={(e) => setFillColor(e?.target?.value || "#ffffff")}
              />
              <label htmlFor="fillColor">选择空白处背景色</label>
            </div>
            <Cropper
              style={{ height: 400, width: 640, background: fillColor }}
              preview=".img-preview"
              aspectRatio={16 / 10}
              src={image}
              background={false}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </Space>
          <div
            style={{
              position: "fixed",
              inset: 0,
              zIndex: isModalOpen ? 1000 : -1,
              background: "rgba(0,0,0,.45)",
              pointerEvents: "auto",
            }}
          >
            <Card
              title="预览"
              extra={
                <Space>
                  <Button type="primary" onClick={handleOk}>
                    下载
                  </Button>
                  <Button onClick={handleCancel}>关闭</Button>
                </Space>
              }
              style={{
                width: "fit-content",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <canvas
                id="canvas"
                width="1280"
                height="800"
                style={{ border: `2px solid #242424` }}
                ref={canvasRef}
              ></canvas>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default Edit;
