import React, { useState } from "react";
import {
  Layout,
  theme,
  Space,
  Typography,
  Row,
  Col,
  Anchor,
  Divider,
} from "antd";
import UrlQueryForm from "./components/Form";
import YoutubePicPreview from "./components/Photo";
import Edit from "./components/Edit";
import TextOCR from "./components/VideoImgOCR";
import MeowHeader from "./components/Meow";
import "./App.css";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const App = () => {
  const [videoId, setVideoId] = useState("");
  const [currentAnchor, setAnchor] = useState("#part-1");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="layout">
      <Header>
        <Title level={4} style={{ color: "#fff", margin: 18 }}>
          猪猪冲浪助手 - web 工具
        </Title>
      </Header>

      <Content style={{ padding: "0 50px", margin: "20px 0" }}>
        <div
          className="site-layout-content"
          style={{ background: colorBgContainer }}
        >
          <Row>
            <Col span={16}>
              <div
                id="part-1"
                style={{
                  minHeight: "100vh",
                  paddingLeft: 32,
                }}
              >
                <MeowHeader />
                <Title level={4} style={{ color: "#242424" }}>
                  Youtube视频封面获取
                </Title>
                <Space direction="vertical">
                  <Space align="left">
                    <UrlQueryForm onSubmit={setVideoId} />
                  </Space>
                  <Space align="center" direction="vertical">
                    <YoutubePicPreview id={videoId} />
                  </Space>
                </Space>
                <Divider />
              </div>
              <div
                id="part-2"
                style={{
                  minHeight: "100vh",
                  paddingLeft: 32,
                }}
              >
                <Title level={4} style={{ color: "#242424" }}>
                  生成b站封面
                </Title>
                <Space align="center" direction="vertical">
                  <Edit />
                </Space>
                <Divider />
              </div>
              <div
                id="part-3"
                style={{
                  minHeight: "100vh",
                  paddingLeft: 32,
                }}
              >
                <Title level={4} style={{ color: "#242424" }}>
                  ChatGPT-3.5
                </Title>
                <Space align="center" direction="vertical">
                  <iframe
                    src="https://ai.ls"
                    maxWidth="100%"
                    frameborder="0"
                    width="400"
                    height="1000"
                  ></iframe>
                </Space>
              </div>
              <div
                id="part-4"
                style={{
                  minHeight: "100vh",
                  paddingLeft: 32,
                }}
              >
                <Title level={4} style={{ color: "#242424" }}>
                  识别文字
                </Title>
                <Space align="center" direction="vertical">
                  <TextOCR />
                </Space>
              </div>
              <div
                id="part-5"
                style={{
                  minHeight: "100vh",
                  paddingLeft: 32,
                }}
              >
                <Title level={4} style={{ color: "#242424" }}>
                  Copy dashboard
                </Title>
                <Space align="center" direction="vertical">
                  <iframe
                    src="https://sss.ninja/share"
                    maxWidth="100%"
                    frameborder="0"
                    width="400"
                    height="1000"
                  ></iframe>
                </Space>
              </div>
            </Col>
            <Col span={8}>
              <Anchor
                currentAnchor={currentAnchor}
                onClick={(_, link) => {
                  const { href } = link;
                  setAnchor(href);
                }}
                style={{
                  marginTop: 50,
                  padding: 20,
                }}
                items={[
                  {
                    key: "part-1",
                    href: "#part-1",
                    title: "Youtube视频封面获取",
                  },
                  {
                    key: "part-2",
                    href: "#part-2",
                    title: "生成b站封面",
                  },
                  {
                    key: "part-3",
                    href: "#part-3",
                    title: "AI",
                  },
                  {
                    key: "part-4",
                    href: "#part-4",
                    title: "文本识别",
                  },
                  {
                    key: "part-5",
                    href: "#part-5",
                    title: "跨设备复制粘贴版",
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©2022 Created by York Liang
      </Footer>
    </Layout>
  );
};

export default App;
