import React, { useEffect, useRef, useState } from "react";
import Tesseract from "tesseract.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Select, Progress } from "antd";
import UploadImg from "./UploadImg";

const TextOCR = () => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const timer = useRef();
  const [cropperImage, setImage] = useState("");
  const [cropper, setCropper] = useState();
  const [translation, changeTranslation] = useState("");
  const [videoSrc, seVideoSrc] = useState("");
  const [lan, changeLan] = useState("chi_sim");
  const [progress, setProgress] = useState(0);

  const onChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const binary = () => {
    const canvas = canvasRef.current;
    const c = canvas.getContext("2d");
    let imgData = c.getImageData(0, 0, canvas.width, canvas.height);
    let index = 255 / 2; //阈值
    for (let i = 0; i < imgData.data.length; i += 4) {
      let R = imgData.data[i]; //R(0-255)
      let G = imgData.data[i + 1]; //G(0-255)
      let B = imgData.data[i + 2]; //B(0-255)
      let Alpha = imgData.data[i + 3]; //Alpha(0-255)
      let sum = (R + G + B) / 3;
      if (sum > index) {
        imgData.data[i] = 255;
        imgData.data[i + 1] = 255;
        imgData.data[i + 2] = 255;
        imgData.data[i + 3] = Alpha;
      } else {
        imgData.data[i] = 0;
        imgData.data[i + 1] = 0;
        imgData.data[i + 2] = 0;
        imgData.data[i + 3] = Alpha;
      }
    }
    c.putImageData(imgData, 0, 0);
    setImage(canvas.toDataURL());
  };

  const capture = (video, scaleFactor = 1) => {
    if (scaleFactor == null) {
      scaleFactor = 1;
    }
    let w = video.videoWidth * scaleFactor;
    let h = video.videoHeight * scaleFactor;
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, w, h);
      setImage(canvas.toDataURL());
      return canvas;
    }

    return null;
  };

  const drawCapturePic = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const cropForgroundImage = cropper.getCroppedCanvas();
      ctx.drawImage(cropForgroundImage, 0, 0, canvas.width, canvas.height);
      return canvas;
    }

    return null;
  };
  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      console.log(video);
    }
  }, []);
  return (
    <>
      <UploadImg onUpload={onChange} />
      <Select
        defaultValue={lan}
        value={lan}
        style={{ width: 120 }}
        onChange={changeLan}
        options={[
          {
            value: "chi_sim",
            label: "中文",
          },
          {
            value: "chi_tra",
            label: "中文-繁体",
          },
          {
            value: "jpn",
            label: "日语",
          },
          {
            value: "eng",
            label: "英语",
          },
        ]}
      />
      <canvas
        id="canvas-video"
        width={300}
        ref={canvasRef}
        style={{ visibility: "hidden", height: 0 }}
      />
      {/* Experiment funtion video */}
      {videoSrc && (
        <video
          width={500}
          preload="auto"
          ref={videoRef}
          controls
          onPlay={() => {
            timer.current = setInterval(
              () => capture(videoRef.current, 0.5),
              1000
            );
          }}
          onPause={() => {
            clearInterval(timer.current);
          }}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      )}
      <p>
        {" "}
        编辑/操作{" "}
        <Button
          primary
          onClick={() => {
            if (!!cropper) {
              const cropForgroundImage = cropper.getCroppedCanvas();
              Tesseract.recognize(cropForgroundImage, lan, {
                logger: (m) => {
                  console.log(m);
                  setProgress(m.progress);
                },
              }).then(({ data: { text } }) => {
                console.log(text);
                changeTranslation(text);
              });
            }
          }}
        >
          翻译
        </Button>
        <Button
          onClick={() => {
            drawCapturePic();
            binary();
          }}
        >
          二值化
        </Button>
      </p>
      {cropperImage && (
        <>
          <Cropper
            style={{ height: 400, width: 640 }}
            preview=".img-preview"
            src={cropperImage}
            background={false}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
          {progress < 1 && progress > 0 ? (
            <>
              翻译中……{" "}
              <Progress percent={Math.floor(progress * 100)} status="active" />
            </>
          ) : (
            <p>{translation}</p>
          )}
        </>
      )}
    </>
  );
};

export default TextOCR;
